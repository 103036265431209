<template>
  <div>

    <el-dialog :visible.sync="listDigShow" v-if="listDigShow" :title="listDigTitle" width="800px" v-model="listDigShow" :close-on-click-modal="false"
               @close="listDigShow=false">
      <el-table
          :data="page.list"
          v-loading="showLoading"
          element-loading-text="拼命加载中"
          border
          width="width:100%; height:50%"
      >
        <el-table-column
            type="index"
            label="序号"
            width="50"
        ></el-table-column>
        <el-table-column
            prop="planid"
            label="跟进计划"
            width="100"
        ></el-table-column>
        <el-table-column
            prop="customer.name"
            label="客户"
            width="100"
        ></el-table-column>
        <el-table-column
            prop="uid"
            label="跟进人"
            width="100"
        ></el-table-column>
        <el-table-column
            prop="target"
            label="跟进目标"
            width="200"
        ></el-table-column>
        <el-table-column prop="result" label="跟进结果"></el-table-column>
        <el-table-column
            prop="status"
            label="状态"
            width="50"
        ></el-table-column>
      </el-table>
      <el-pagination @current-change="devlogChange"
                     :current-page="page.pageNum"
                     :page-size="page.pageSize" layout="total, prev, pager, next, jumper"
                     :total="page.total">
      </el-pagination>

      <div slot="footer" class="dialog-footer" style="text-align:right; margin-top: 10px;">
        <el-button @click="handle_cancel()">关闭</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import PM from '../../api/base/api_pm';
import syscomm from '../../utils/syscomm';
import COMM from "../../api/base/api_comm";
import FOLLOWREC from "@/api/biz/api_followrecord";
export default {
  name: "recordlistdig", // 项目选择列表
  components: {
  },
  props: {
    listDigShow:{
      type: Boolean
    },
    listDigTitle: {
      type: String
    },
    customerInfo: {
      type: Object
    }
  },
  data() {
    return {
      showLoading: false,
      page: {list: [], pageNum: 1, pageSize: 10, total: 0},
      auth: {}
    }
  },
  // 初始化加载
  mounted() {
      this.init();
  },
  // 方法定义
  methods: {
    init() {
      this.auth=this.$getAuth('/followList');
      this.initPageData();
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;

      FOLLOWREC.list({
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        custid: this.customerInfo.id
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          var data = res.pageData;
          this.page.total = data.total;
          this.page.list = data.list;
        } else {
          this.$message.error(res.msg);
          this.page.list = [];
        }
      });
    },

    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },

    // 取消
    handle_cancel() {
      this.$parent.recordListShow = false;
    },
  }
}
</script>