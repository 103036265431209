/**
 * Created by xiongxiaomeng.
 * 跟进计划相关api
 */
import * as API from '../../utils/request';

export default {
    //管理
    info: params => {  return API.POST('followplan/info', params)},
    list: params => {  return API.GET('followplan/list', params)},
    get: params => {  return API.GET('followplan/get', params)},
    getByParentId: params => {  return API.GET('followplan/getByParentId', params)},
    delete: params => {  return API.DELETE('followplan/delete', params)},
    close: params => { return API.POST("followplan/close", params) },
    listWithCustomer: params => {return API.GET('followplan/listWithCustomer', params)},
}
