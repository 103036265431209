<template>
  <div>

    <el-card class="box-card" style="margin-bottom: 5px; text-align: center;">
      <div slot="header" class="clearfix" style="font-weight: bold;">
        <span>{{customerInfo.name}}</span>
        <el-button style="float: right" type="text" @click="openRecordListDialog()">跟进历史记录</el-button>
      </div>
      <div class="text item" style="margin-top: 5px;">
        <el-tag style="margin-left: 5px;"
                v-for="(tag, index) in customerCheckedTags"
                :key="tag.name">
          {{tag.name}}
        </el-tag>
      </div>
    </el-card>

    <el-card class="box-card" style="margin-bottom: 5px; text-align: center;">
      <el-form :model="followRecord" ref="followRecord" class="demo-ruleForm">
        <el-row>
          <el-col :span="6">
            <el-form-item label="类型" label-width="100px" prop="type">
              <el-select v-model="followRecord.type" placeholder="请选择跟进类型">
                <el-option v-for="item in types" :key="item.value" :label="item.name" :value="parseInt(item.value)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="目标" label-width="100px" prop="target">
              <el-input type="textarea" :rows="2" v-model="followRecord.target"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结果" label-width="100px" prop="result">
              <el-input type="textarea" :rows="2" v-model="followRecord.result"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <hr style="margin-top: 20px;"/>
        <el-row style="margin-top: 20px; margin-bottom: 20px;">
          <el-col :span="24" style="text-align: center; font-weight: bold;">下次跟进信息</el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="跟进时间" label-width="100px" prop="result">
              <el-date-picker
                  v-model="followRecord.nextfollowtime"
                  type="date">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="类型" label-width="100px" prop="type">
              <el-select v-model="followRecord.nextfollowtype" placeholder="请选择跟进类型">
                <el-option v-for="item in types" :key="item.value" :label="item.name"  :value="parseInt(item.value)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="目标" label-width="100px" prop="target">
              <el-input type="textarea" :rows="2" v-model="followRecord.nextTarget"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="auth['followrecord:edit']" type="primary" @click="edit_save('followRecord')">确 定</el-button>
      </div>

      <FollowRecordDig :listDigTitle="recordListTitle" :customerInfo="customerInfo" :listDigShow="recordListShow"></FollowRecordDig>
    </el-card>
  </div>
</template>

<script>
import FOLLOW from "../../api/biz/api_followplan";
import moment from "moment";
import FOLLOWRECORD from "../../api/biz/api_followrecord";
import CUS from "@/api/biz/api_customer";
import COMM from "@/api/base/api_comm";
import FollowRecordDig from './recordListDialog';

export default {
  components: {
    FollowRecordDig
  },
  data() {
    return {
      followRecord: {
        id: this.$route.query.followRecordId
      },
      customerInfo: {
        id: this.$route.query.customerId
      },
      types: [],
      recordListShow: false,
      recordListTitle: '客户跟进记录',
      customerTagList: [],
      customerCheckedTags: [],
      auth: {}
    };
  },

  mounted() {
    this.init();
  },
  // 方法定义
  methods: {
    init() {
      this.init_base();
      this.auth=this.$getAuth('/followList');
      this.initPageData();
    },
    // 加载初始数据支持
    init_base(){
      COMM.getObjType({type: 'followtype'}).then((res) => {
        this.types = res;
      });
      COMM.getObjType({type: 'customertag'}).then((res) => {
        this.customerTagList = res;
      });
    },
    initPageData() {
      // 执行查询
      CUS.get({
        id: this.customerInfo?.id
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          this.customerInfo = res.data;
          this.buildCustomerTags(this.editCustomer);
        } else {
          this.$message.error(res.msg);
          this.initAddData();
        }
      });

      // 跟进记录
      if (this.followRecord&&this.followRecord?.id >= 0) {
        // 执行查询
        FOLLOWRECORD.get({
          id: this.followRecord?.id
        }).then((res) => {
          this.showLoading = false;
          if (200 == res.code) {
            this.followRecord = res.data;
          } else {
            this.$message.error(res.msg);
            this.initAddData();
          }
        });

        // 下次跟进计划
        // 执行查询
        // FOLLOW.getByParentId({
        //   parentid: this.followRecord.id
        // }).then((res) => {
        //   this.showLoading = false;
        //   if (200 == res.code) {
        //     if (res.data == null || res.data.length == 0) {
        //       return;
        //     }
        //     this.followRecord.nextPlanId = res.data.id;
        //     this.followRecord.nextTarget = res.data.target;
        //     this.followRecord.nextfollowtime = res.data.starttime;
        //     this.followRecord.nextfollowtype = res.data.type;
        //   } else {
        //     this.$message.error(res.msg);
        //   }
        // });
      }

    },

    // 构造客户标签
    buildCustomerTags(customerInfo) {
      if (customerInfo == null) {
        return;
      }

      let tag = customerInfo.tag;
      for (const index in this.customerTagList) {
        let customerTag = this.customerTagList[index];
        if (tag.indexOf(customerTag['value'] + ',') >= 0) {
          this.customerCheckedTags.push(customerTag);
        }
      }
    },

    edit_save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.pmInfo)
          let nextfollowtime = this.followRecord['nextfollowtime'];
          this.followRecord['nextfollowtime'] = moment(nextfollowtime).format('YYYY-MM-DD');
          this.followRecord['custid'] = this.customerInfo.id;

          // console.log('this.customerInfo.id = ' + this.customerInfo.id);

          FOLLOWRECORD.info(this.followRecord).then((res) => {
            if (200 == res.code) {
              this.$message.success("操作成功！");
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },

    openRecordListDialog() {
      this.recordListShow = true;
    }
  },
};
</script>

<style scoped>
.next-follow {
  line-height: 30px;
  text-align: center;
  margin-bottom: 20px;
  opacity: 0.7;
}
.recordlist-link {
  line-height: 1em;
  margin-bottom: 20px;
  padding-right: 30px;
  text-align: right;
}
</style>