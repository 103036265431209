/**
 * Created by bootdo.
 * 用户相关api
 */
import * as API from '../../utils/request';

export default {

    //此方法废弃，禁止调用或设置（add/update权限）
    info: params => {  return API.POST('pm/info', params)},
    //
    get: params => {  return API.GET('pm/get', params)},
    add: params => {  return API.POST('pm/add', params)},
    update: params => {  return API.POST('pm/update', params)},
    list: params => {  return API.GET('pm/list', params)},
    delete: params => {  return API.DELETE('pm/delete', params)},
    config: params => { return API.GET('pm/config', params) },

    listUncompleteProjectWithVeriryPlan: params => { return API.GET('pm/listUncompleteProject', params) },
    listByVerifyPlanId: params => { return API.GET('pm/listByVerifyPlanId', params) },
    listByCustomerIdAndNotVerifyPlanId: params => { return API.GET('pm/listByCustomerIdAndNotVerifyPlanId', params) },
    listUncompleteProject: params => { return API.GET('pm/listUncompleteProject', params) },
    countListUncompleteProject: params => { return API.GET('pm/countListUncompleteProject', params) },
    
}
