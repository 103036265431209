/**
 * Created by xiongxiaomeng.
 * 跟进信息相关api
 */
import * as API from '../../utils/request';

export default {
    //管理
    info: params => {  return API.POST('follow/info', params)},
    list: params => {  return API.GET('follow/list', params)},
    get: params => {  return API.GET('follow/get', params)},
    delete: params => { return API.DELETE('follow/delete', params) },
    getLatestByFollowPlanId: params => { return API.GET('follow/getLatestByFollowPlanId',params)},
}
